const config = {
  lang: localStorage.lang ?? 'ru',
  logo: '/images/logosm.png',
  logo_corp: '/images/logo_kv.png',
  logo_top: '/images/logosm-line.png',
  AppName: 'ПУД',
  AppName2: 'ПУД',
  version: '0.4.1',
  fias: false,
  api_url: process.env.REACT_APP_API_URL,
};

export default config;
